import React, { useState, useEffect } from 'react';
import PopupWithForm from './PopupWithForm';

export default function AddBulkPopup({ isOpen, onClose, onAdd }) {
  const [fileList, setFileList] = useState('');
  const [isClassPhoto, setIsClassPhoto] = useState(false);

  function handleSubmit(evt) {
    evt.preventDefault();
    const urls = fileList.split('\n').filter((url) => url.trim() !== '');
    const fileObjects = urls.map((url) => {
      const filename = url.substring(url.lastIndexOf('/') + 1);
      return {
        name: filename,
        link: url,
        isClassPhoto: isClassPhoto,
      };
    });
    fileObjects.forEach((file) => {
      onAdd(file);
    });
  }

  useEffect(() => {
    setFileList('');
  }, [isOpen]);

  return (
    <PopupWithForm
      name="place"
      title="Add new photo"
      onSubmit={handleSubmit}
      onClose={onClose}
      isOpen={isOpen}
      buttonText="Create"
    >
      <textarea
        className="popup__input popup__input_field_link"
        id="input-link"
        name="link"
        placeholder="Enter file URLs (one per line)"
        required
        value={fileList}
        onChange={(evt) => setFileList(evt.target.value)}
      />
      <span className="popup__error" id="input-link-error"></span>
      <div className="popup__wrapper">
        <label className="popup__label" htmlFor="input-isClassPhoto">
          Is it a class photo?
        </label>
        <input
          className="popup__input_field_checkbox"
          id="input-isClassPhoto"
          type="checkbox"
          name="isClassPhoto"
          checked={isClassPhoto}
          onChange={(evt) => setIsClassPhoto(evt.target.checked)}
        />
      </div>
    </PopupWithForm>
  );
}