import React from 'react';
import './Promo.css';
import { Link } from 'react-scroll';
import globe from '../../../images/globe.svg';

export default function Promo() {
  return (
    <section className='promo'>
      <div className='promo__container'>
        <div className='promo__container-intro'>
          <h1 className='promo__title'>Project of Web&#8209;development faculty graduate.</h1>
          <p className='promo__subtitle'>Scroll down to learn about the Project and its creator</p>
          <Link className='promo__link' to='about' duration={500} smooth={true}>Learn More</Link>
        </div>
        <img className='promo__globe' src={globe} alt='Globe' />
      </div>
    </section>
  );
};

