import React from 'react';
import { useState, useEffect } from 'react';
import PopupWithForm from './PopupWithForm';

export default function AddPlacePopup({ isOpen, onClose, onAdd }) {
  const [placeName, setPlaceName] = useState('');
  const [placeUrl, setPlaceUrl] = useState('');
  const [isClassPhoto, setIsClassPhoto] = useState(false);

  function handleSubmit(evt) {
    evt.preventDefault();
    onAdd({
      name: placeName,
      link: placeUrl,
      isClassPhoto: isClassPhoto,
    });
  }

  useEffect(() => {
    setPlaceName('');
    setPlaceUrl('');
  }, [isOpen])

  return (
    <PopupWithForm
      name="place"
      title="Add new photo"
      onSubmit={handleSubmit}
      onClose={onClose}
      isOpen={isOpen}
      buttonText="Create"
    >
      <input
        className="popup__input popup__input_field_title"
        id="input-title"
        type="text"
        name="name"
        placeholder="Photo name"
        minLength="2"
        maxLength="30"
        required
        value={placeName || ''}
        onChange={(evt) => setPlaceName(evt.target.value)}
      />
      <span id="input-title-error" className="popup__error"></span>
      <input
        className="popup__input popup__input_field_link"
        id="input-link"
        type="url"
        name="link"
        placeholder="Picture link"
        required
        value={placeUrl || ''}
        onChange={(evt) => setPlaceUrl(evt.target.value)}
      />
      <span className="popup__error" id="input-link-error" ></span>
      <div className='popup__wrapper'>
        <label className='popup__label' htmlFor="input-isClassPhoto">Is it a class photo?</label>
        <input
          className="popup__input_field_checkbox"
          id="input-isClassPhoto"
          type="checkbox"
          name="isClassPhoto"
          checked={isClassPhoto}
          onChange={(evt) => setIsClassPhoto(evt.target.checked)}
        /></div>


    </PopupWithForm>
  )
}