import React from 'react';
import { Link } from 'react-router-dom';
import siteLogo from '../images/logo.png'

export default function Footer() {
  return (
    <footer className="footer">
      <Link className='footer__link' to='/developer'>
        <p className="footer__copyright">WhatsApp +7(985)835-7632</p>
        <p className="footer__copyright">Email: aabilov@hinksonca.net</p>
        <p className="footer__copyright">© {new Date().getFullYear()} Developed by Alexander Abilov</p>
      </Link>

      <Link className='footer__link' to='/'><img className="footer__logo" alt='logo' src={siteLogo} /></Link>

    </footer>
  )
}