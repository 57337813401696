import React from 'react';
import './Student.css';
import studPhoto from '../../../images/DSC05239.jpg';
import { Link } from 'react-router-dom';
import Portfolio from '../Portfolio/Portfolio'

export default function Student() {
  return (
    <section className='stud' id='student'>
      <h2 className='stud__title'>About Me</h2>
      <div className='stud__wrapper'>
        <div className='stud__content'>
          <h3 className='stud__name'>Alexander</h3>
          <p className='stud__job'>Full-stack Developer</p>
          <p className='stud__info'>
          I was born in the beautiful city of Karaganda, Kazakhstan. I am happily married to my wonderful wife, Katya. 
          Photography and playing drums are two passions that bring me immense joy. 
          Recently, I completed professional development courses at Yandex Practicum, which inspired me to create this website for the convenience of our wonderful staff and parents. 
          I hope you will have a seamless and enjoyable experience by using this platform.
          </p>
          <Link className='stud__link' to={'https://github.com/Screench'} target='_blank'>
            Github
          </Link>
        </div>
        <img className='stud__photo' src={studPhoto} alt='Alexander' />
      </div>
      <Portfolio />
    </section>
  )
};
