import React from 'react';
import './AboutProject.css';

export default function AboutProject() {
  return (
    <section id='about' className='about'>
      <h2 className='about__title'>About the Project</h2>
      <ul className='about__project'>
        <li className='about__project-item'>
        <h3 className='about__project-subtitle'>The Inspiration</h3>
          <p className='about__project-text'>The inception of this project was influenced by the rich history of our school and all remarkable individuals 
          who have dedicated their lives to shaping the futures of our students, as well as the place itself. Spanning over three decades, the photography history 
          transcends mere snapshots of time, it represents real stories of triumphs, struggles, sacrifices, and unwavering dedication. When you find your photographs here
          remember that you are part of something truly extraordinary.</p>
        </li>
        <li className='about__project-item'>
          <h3 className='about__project-subtitle'>The Work</h3>
          <p className='about__project-text'>The development of this online application alone spanned a period of 5 weeks. 
          The initial week was dedicated to programming the backend, which encompasses the server-side software responsible for storing and 
          delivering the necessary data. The subsequent four weeks were allocated to coding the frontend, the client-side software 
          that operates on your computer. This includes various aspects such as design, layout, content, and interactive functionality. 
          There were moments when certain tasks appeared daunting to implement, yet each challenge was like a splinter in a brain which gave no rest until solved.</p>
        </li>
      </ul>
      <div className='about__week'>
        <h4 className='about__week-style about__week-subtitle about__week-subtitle_mod'>1 week</h4>
        <h4 className='about__week-style about__week-subtitle'>4 weeks</h4>
        <p className='about__week-style about__week-description'>Backend</p>
        <p className='about__week-style about__week-description'>Frontend</p>
      </div>
    </section>
  );
};
