import React, { useContext, useEffect, useRef, useState } from 'react';
import PopupWithForm from './PopupWithForm';
import { CurrentUserContext } from '../contexts/CurrentUserContext';
import { siteUrl } from '../utils/auth';

export default function FileUploadPopup({ isOpen, onClose }) {
  const currentUser = useContext(CurrentUserContext);
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false); // State to track upload status
  const inputFiles = useRef();

  useEffect(() => {
    if (inputFiles.current) {
      inputFiles.current.value = '';  // Clear file input on open/close
    }
  }, [isOpen]);

  function handleFileChange(evt) {
    setFiles(evt.target.files); // Update file state on selection
  }

  async function handleSubmit(evt) {
    evt.preventDefault();
    
    // Create FormData to append selected files
    const formData = new FormData();
    Array.from(files).forEach(file => formData.append('files', file));

    try {
      setIsUploading(true); // Set uploading status to true
      const token = localStorage.getItem('jwt');  // Retrieve JWT token from local storage

      // Send the files to the backend using the upload endpoint
      const response = await fetch(`${siteUrl}upload`, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Files uploaded successfully:', result);

        // Map file names to URLs and join them with new lines
        const fileUrls = result.files.map(fileName => `https://myphotodelight.ru/static/uploads/2024-25/${fileName}`).join('\n');
        
        // Copy URLs to clipboard
        await navigator.clipboard.writeText(fileUrls);
        console.log('File URLs copied to clipboard:', fileUrls);
      } else {
        console.error('File upload failed');
      }
    } catch (error) {
      console.error('Error uploading files:', error);
    } finally {
      setIsUploading(false); // Reset uploading status after completion
    }
  }

  return (
    <PopupWithForm
      name="file-upload"
      isOpen={isOpen}
      onClose={onClose}
      title="Upload files"
      onSubmit={handleSubmit}
      buttonText={isUploading ? "Uploading..." : "Upload"}  // Change button text based on upload status
    >
      <label className="popup__form">
        <input
          className="popup__input popup__input_type_file"
          id="input-files"
          name="files"
          type="file"
          ref={inputFiles}
          multiple  // Allow multiple file selection
          onChange={handleFileChange}  // Handle file input change
          required
        />
        <span className="popup__error" id="input-files-error" />
      </label>
    </PopupWithForm>
  );
}