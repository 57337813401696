import React from 'react'
import './Preloader.css'
import preloaderCat from '../../images/preloaderCat.gif'

export default function Preloader () {

    
    return (
        <div className='preloader'>
            <div className='preloader__container'>
                {/* <span className='preloader__round'></span> */}
                <img src={preloaderCat} alt='loading...'/>
            </div>
        </div>
    )
};
