import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';

export default function Register({ handleRegistration }) {
  const [formInputs, setFormInputs] = useState({
    password: '',
    email: '',
  });

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    let email = searchParams.get('email') || '';
    let password = searchParams.get('password') || '';

    // Pre-fill the form with email and password from the URL if available
    setFormInputs({ email, password });

    // Auto-submit only once when both email and password are present
    if (email && password) {
      handleRegistration(email, password);
    }
  }, []);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const { email, password } = formInputs;
    handleRegistration(email, password);
  };

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    setFormInputs((prevFormInputs) => ({
      ...prevFormInputs,
      [name]: value,
    }));
  };

  return (
    <div className='authentication'>
      <h2 className='authentication__title'>Register</h2>
      <form name='register' className='authentication__form' onSubmit={handleSubmit}>
        <input
          type='email'
          name='email'
          className='authentication__input'
          placeholder='E-mail'
          onChange={handleChange} 
          value={formInputs.email}  
          required
        />
        <input
          type='password'
          name='password'
          className='authentication__input'
          placeholder='Password'
          onChange={handleChange} 
          value={formInputs.password}
          required
        />
        <button className='authentication__submit' type='submit'>Register</button>
        <Link className='authentication__link' to='/sign-in'>Already registered? Log in</Link>
      </form>
    </div>
  );
}
