import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import logo from '../images/logo.png';

export default function Login({ onLogin }) {
  const [formInputs, setFormInputs] = useState({
    password: '',
    email: '',
  });

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email') || '';
    const password = searchParams.get('password') || '';

    // Automatically fill in the email and password from the URL if they exist
    setFormInputs({ email, password });

    // Optionally trigger login automatically if email and password are present
    if (email && password) {
      onLogin(email, password);
    }
  }, [location, onLogin]);

  function handleSubmit(evt) {
    evt.preventDefault();
    const { email, password } = formInputs;
    onLogin(email, password);
  }

  function handleChange(evt) {
    const { name, value } = evt.target;
    setFormInputs((prevFormInputs) => ({ ...prevFormInputs, [name]: value }));
  }

  return (
    <div className='authentication'>
      <div className='authentication__title'>
        <img width='210px' src={logo} alt='logo' />
      </div>
      <form name='login' className='authentication__form' onSubmit={handleSubmit}>
        <input
          className='authentication__input'
          autoComplete='off'
          name='email'
          type='email'
          placeholder='E-mail'
          onChange={handleChange}
          value={formInputs.email || ''}
          required
        />

        <input
          className='authentication__input'
          name='password'
          type='password'
          placeholder='Password'
          onChange={handleChange}
          value={formInputs.password || ''}
          required
        />

        <button className='authentication__submit' type='submit'>
          Log in
        </button>
      </form>
    </div>
  );
}
