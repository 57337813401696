import React from 'react';
import {Navigate} from 'react-router-dom';

const ProtectedRoute = ({
  element: Component, ...props }) => {
  return (props.loggedIn ? <Component {...props} /> : <Navigate to='/sign-in' replace />)
}

// const ProtectedRoute = ({ 
//   element: Component, ...props }) => {
//   if (props.loggedIn) return <Component {...props} />
//   else return <Navigate to='/' />
// };

export default ProtectedRoute;