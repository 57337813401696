import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import {digitalOptionCost, smallPrintCost, bigPrintCost} from '../utils/prices';

export default function CheckoutPopup({ isOpen, onClose, isLoading, setIsLoading, smallPrintCounterSum, bigPrintCounterSum, digitalOptionCounterSum, yearbookOptionCounterSum, email, cards, onSuccess, onFail }) {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    onClose();
    setIsLoading(true)

    emailjs.sendForm('service_561hbko', 'template_5fpfrf9', form.current, 'GrCFGQfjF4yWj0eR1')
      .then((result) => {
        setIsLoading(false)
        onSuccess('Great! Your order is being processed. You will receive an email shortly');
      }, (error) => {
        setIsLoading(false)
        onFail();
      });
  };

  const grandTotal = (digitalOptionCounterSum * digitalOptionCost + smallPrintCounterSum * smallPrintCost + bigPrintCounterSum * bigPrintCost)

  const usedCardsOnly = cards.filter(obj =>
    obj.yearbookOption.length > 0 ||
    obj.downloadOption.length > 0 ||
    obj.smallPrintCounter > 0 ||
    obj.bigPrintCounter > 0
  )

  const newCards = usedCardsOnly.map(({ createdAt, _id, owner, isClassPhoto, ...keepAttrs }) => keepAttrs)

  const yearbook = newCards
    .filter(({ yearbookOption }) => yearbookOption.length > 0)
    .map(({ name }) => name)

  const download = newCards
    .filter(({ downloadOption }) => downloadOption.length > 0)
    .map(({ name }) => name)

    const bigPrint = {};
    newCards
      .filter(({ bigPrintCounter }) => bigPrintCounter > 0)
      .forEach(item => bigPrint[item.name] = `order ${item.bigPrintCounter} pcs`);

  const smallPrint = {};
  newCards
    .filter(({ smallPrintCounter }) => smallPrintCounter > 0)
    .forEach(item => smallPrint[item.name] = `order ${item.smallPrintCounter} pcs`);

  return (

    <div className={`popup ${isOpen ? 'popup_opened' : ''}`}>
      <div className='popup__container' >

        <h2 className='popup__title'>Order Summary</h2>
        <table>
          <thead>
            <tr>
              <th>Selected Options</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Download</td>
              <td>{digitalOptionCounterSum}</td>
              <td>{digitalOptionCost}</td>
              <td>{digitalOptionCounterSum * digitalOptionCost}</td>
            </tr>
            <tr>
              <td>Small Print</td>
              <td>{smallPrintCounterSum}</td>
              <td>{smallPrintCost}</td>
              <td>{smallPrintCounterSum * smallPrintCost}</td>
            </tr>
            <tr>
              <td>Big Print</td>
              <td>{bigPrintCounterSum}</td>
              <td>{bigPrintCost}</td>
              <td>{bigPrintCounterSum * bigPrintCost}</td>
            </tr>
            <tr>
              <th colSpan='3'>Grand Total</th>
              <th>{grandTotal}</th>
            </tr>
          </tbody>
        </table>

        <button type='button' className='popup__close-btn' onClick={onClose} />

        <p>Please transfer {grandTotal} rubles to +7 985 835 7632 (Alexandr A) in SberBank</p>
        <p>Printed Photos usually processed within 3-4 days. You can pick them at school's reception</p>
        <p>Photos for download will be sent to this address: {email}</p>

        <form ref={form} onSubmit={sendEmail}>

          <label className='popup__label'>Comment to the order:</label><br />
          <input className='popup__textarea' type='textarea' maxLength={200} rows='2' name='comment' placeholder='Alternative email or question' /><br />

          <input type='hidden' name='grandTotal' value={grandTotal} />
          <input type='hidden' name='email' value={email} />
          <input type='hidden' name='message' value={JSON.stringify({ newCards }, null, 4)} />

          <input type='hidden' name='digitalOptionCounterSum' value={digitalOptionCounterSum} />
          <input type='hidden' name='yearbookOptionCounterSum' value={yearbookOptionCounterSum} />
          <input type='hidden' name='smallPrintCounterSum' value={smallPrintCounterSum} />
          <input type='hidden' name='bigPrintCounterSum' value={bigPrintCounterSum} />

          <input type='hidden' name='download' value={JSON.stringify(download, null, '\t')} />
          <input type='hidden' name='yearbook' value={JSON.stringify(yearbook, null, '\t')} />
          <input type='hidden' name='smallPrint' value={JSON.stringify(smallPrint, null, '\t')} />
          <input type='hidden' name='bigPrint' value={JSON.stringify(bigPrint, null, '\t')} />
          
          <input type='submit' width='100%' className='popup__submit-btn' value='Submit Order for Processing' />
        </form>

        

      </div>
    </div>
  )
}